import { Moonbar__factory } from "../contracts";
import { PancakePair__factory } from "../contracts/factories/PancakePair__factory";
import useEthers from "./useEthers";

export const useMoonbarContract = () => {
	const ethers = useEthers();
	return Moonbar__factory.connect(
		"0x47e28936D9c7944D106e46295d87A8E4c1Be818c",
		ethers
	);
};

export const usePancakePairContract = () => {
	const ethers = useEthers();
	return PancakePair__factory.connect(
		"0x86e88Ae47DfeabD0f208C67fe4BD62aF51220704",
		ethers
	);
};
