import React from "react";
import moment from "moment";

interface Props {
	until: moment.Moment;
}

const Countdown: React.FC<Props> = ({ until }) => {
	const [days, setDays] = React.useState("0");
	const [hours, setHours] = React.useState("0");
	const [minutes, setMinutes] = React.useState("0");
	const [seconds, setSeconds] = React.useState("0");
	const [count, setCount] = React.useState(0);

	React.useEffect(() => {
		const intv = setInterval(() => {
			setCount((c) => c + 1);
		}, 1000);

		return () => {
			clearInterval(intv);
		};
	}, []);

	React.useEffect(() => {
		const now = moment();
		const currentDate = moment(moment(now).format("YYYY-MM-DD HH:mm:ss"));
		const availableDate = moment(moment(until).format("YYYY-MM-DD HH:mm:ss"));
		const duration = moment.duration(availableDate.diff(currentDate));
		const days = String(duration.days());
		const hours = String(duration.hours());
		const minutes = String(duration.minutes());
		const seconds = String(duration.seconds());

		setDays(days);
		setHours(hours);
		setMinutes(minutes);
		setSeconds(seconds);
	}, [count]);

	return (
		<div>
			<h2>
				Countdown: {days} days {hours} hours {minutes} minutes {seconds} seconds
			</h2>
		</div>
	);
};

export default Countdown;
