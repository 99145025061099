import React from "react";
import { useWallet } from "@binance-chain/bsc-use-wallet";

import Button from "./Button";

interface Props {
	children: React.ReactNode;
	placeholder?: string;
}

const RequiresWallet: React.FC<Props> = ({
	children,
	placeholder = "Connect Wallet",
}) => {
	const { account, connect } = useWallet();

	const handleConnect = () => {
		connect("injected"); // injected = metamask
	};

	if (account) {
		return <>{children}</>;
	}

	return <Button onClick={handleConnect}>{placeholder}</Button>;
};

export default RequiresWallet;
