import React from "react";

import { useWallet } from "@binance-chain/bsc-use-wallet";
import { BigNumber, ethers, utils } from "ethers";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
	useMoonbarContract,
	usePancakePairContract,
} from "../hooks/useContract";

import ItemBox from "../components/ItemBox";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import RequiresWallet from "../components/RequiresWallet";
import Countdown from "../components/Countdown";

import imageMoonbar from "./moonbar-sideways.png";
import iconPool from "./icons/pool.png";
import iconComputer from "./icons/computer.png";
import iconMoney from "./icons/money.png";
import iconLetter from "./icons/letter.png";
import iconContract from "./icons/contract.png";
import iconPoolDeep from "./icons/pooldeep.png";
import useEthers from "../hooks/useEthers";

const Dashboard = () => {
	const Ethers = useEthers();

	const { account } = useWallet();
	const moonbarContract = useMoonbarContract();
	const pancakePairContract = usePancakePairContract();

	const [userMoonbarBalance, setUserMoonbarBalance] = React.useState(
		BigNumber.from(0)
	);
	const [pendingReward, setPendingReward] = React.useState(BigNumber.from(0));
	const [nextAvailableClaimDate, setNextAvailableClaimDate] = React.useState(
		BigNumber.from(0)
	);
	const [rewardPool, setRewardPool] = React.useState(BigNumber.from(0));
	const [isFetchingData, setIsFetchingData] = React.useState(false);
	const [isClaiming, setIsClaiming] = React.useState(false);
	const [maxTransactionAmount, setMaxTransactionAmount] = React.useState(
		BigNumber.from(0)
	);
	const [totalLiquidityBNB, setTotalLiquidityBNB] = React.useState(
		BigNumber.from(0)
	);
	const [totalLiquidityMoonbar, setTotalLiquidityMoonbar] = React.useState(
		BigNumber.from(0)
	);
	const [burned, setBurned] = React.useState(BigNumber.from(0));

	const [totalLiquidity, setTotalLiquidity] = React.useState(BigNumber.from(0));
	const [rewardCycleBlock, setRewardCycleBlock] = React.useState(
		BigNumber.from(0)
	);

	const [totalSupply, setTotalSupply] = React.useState(BigNumber.from(0));

	React.useEffect(() => {
		(async () => {
			const [reserveMoonbar, reserveBNB] =
				await pancakePairContract.getReserves();
			setTotalLiquidityBNB(reserveBNB);
			setTotalLiquidityMoonbar(reserveMoonbar);

			setTotalLiquidity(await pancakePairContract.totalSupply());
			setRewardCycleBlock(await moonbarContract.getRewardCycleBlock());
			setRewardPool(await Ethers.getBalance(moonbarContract.address));
			setMaxTransactionAmount(await moonbarContract.maxTxAmount());
			setTotalSupply(await moonbarContract.totalSupply());
			// setawait moonbarContract.rewardThreshold()
			setBurned(
				await moonbarContract.balanceOf(
					"0x000000000000000000000000000000000000dEaD"
				)
			);
		})();

		if (account === null) {
			setUserMoonbarBalance(BigNumber.from(0));
			setNextAvailableClaimDate(BigNumber.from(0));

			return;
		}

		setIsFetchingData(true);
		(async () => {
			setUserMoonbarBalance(await moonbarContract.balanceOf(account));
			setPendingReward(await moonbarContract.calculateBNBReward(account));
			setNextAvailableClaimDate(
				await moonbarContract.nextAvailableClaimDate(account)
			);
			setIsFetchingData(false);
			// use this for fetching price
			// https://api.pancakeswap.info/api/v2/tokens/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82
		})();
	}, [account]);

	async function claimReward() {
		setIsClaiming(true);
		try {
			await moonbarContract.connect(Ethers.getSigner()).claimBNBReward();
			toast(`🍬 claimed!`, { type: "success" });
		} catch (e) {
			console.log("error claiming:", e);
			toast(`😢 failed to claim reward: ${e.message}`, { type: "error" });
		} finally {
			setIsClaiming(false);
		}
	}

	return (
		<section className="container m-auto p-6 sm:p-0">
			<div className="grid grid-cols-1 sm:grid-cols-3">
				<div></div>
				<div className="relative">
					<ItemBox>
						<div className="rainbow rainbow2"></div>
						<img
							src={imageMoonbar}
							alt="Moonbar Logo"
							className="h-20 m-auto mb-4 transform transition duration-300 hover:-rotate-12"
						/>
						<h2 className="text-xl font-semibold">MOONBAR Dashboard</h2>
						<p className="mt-2">Your Reward Share</p>
						<p>
							{userMoonbarBalance.isZero() || totalSupply.isZero()
								? ethers.FixedNumber.from(0).toString()
								: ethers.FixedNumber.from(userMoonbarBalance)
										.mulUnsafe(ethers.FixedNumber.from("100"))
										.divUnsafe(
											ethers.FixedNumber.from(totalSupply).subUnsafe(
												ethers.FixedNumber.from(burned)
											)
										)
										.toString()}
							%
						</p>
						<p className="text-blue text-sm mt-4">
							Buy more to increase your BNB Reward Share!
							<br />
							Earn $BNB while you eat!
						</p>

						<div className="my-4">
							<Button
								onClick={() => {
									window.open(
										"https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x47e28936D9c7944D106e46295d87A8E4c1Be818c",
										"_blank"
									);
								}}
							>
								Buy MOONBAR
							</Button>
							<ToastContainer />
						</div>
					</ItemBox>
				</div>
				<div></div>
			</div>

			<div className="my-8">
				<ItemBox>
					<p className="text-2xl mb-4">
						My collectible BNB: {utils.formatEther(pendingReward)} BNB
					</p>
					{/* <p className="text-blue">
						*Forecasted Annual BNB Rewards: 0.0000 BNB
					</p> */}
					<p>
						*Pool and Reward are always changing based on buys, sells, collects
						by others and your percentage holdings.
					</p>
					<h2 className="text-xl font-semibold my-2">
						Next collect date:{" "}
						{nextAvailableClaimDate.isZero()
							? "unavailable"
							: moment.unix(nextAvailableClaimDate.toNumber()).toLocaleString()}
					</h2>

					{nextAvailableClaimDate.isZero() ? (
						"--"
					) : (
						<Countdown until={moment.unix(nextAvailableClaimDate.toNumber())} />
					)}

					<h2 className="text-xl font-semibold my-2">
						Reward Cycle: {moment.duration(rewardCycleBlock.toNumber(), 'seconds').asDays()}{" "}
						Days
					</h2>
					<p>
						{userMoonbarBalance.isZero() ? "Buy MOONBAR to collect BNB!" : ""}
					</p>
					<div className="my-4">
						<RequiresWallet>
							<Button
								disabled={
									!pendingReward.gt(BigNumber.from("0")) ||
									moment.unix(nextAvailableClaimDate.toNumber()).unix() -
										moment().unix() >=
										0
								}
								onClick={claimReward}
							>
								<div className="h-1">{isClaiming && <Spinner />}</div>Claim
								Reward
							</Button>
						</RequiresWallet>
					</div>
				</ItemBox>
			</div>

			<div className="grid grid-cols-3 gap-4">
				<ItemBox>
					<img className="m-auto h-10 my-2" src={iconMoney} alt="" />
					<p className="">
						Transaction Limit {utils.formatUnits(maxTransactionAmount, 9)}
					</p>
				</ItemBox>
				<ItemBox>
					<img className="m-auto h-10 my-2" src={iconPool} alt="" />
					<p>Total liquidity pool</p>
					<p className="text-xs mt-2">LP {utils.formatEther(totalLiquidity)}</p>
				</ItemBox>
				<ItemBox>
					<img className="m-auto h-10 my-2" src={iconLetter} alt="" />
					<p>$MOONBAR price</p>
					<a
						target="_blank"
						href="https://poocoin.app/tokens/0x47e28936d9c7944d106e46295d87a8e4c1be818c"
						className="underline text-xs"
						rel="noreferrer"
					>
						Check $MOONBAR price on Poocoin
					</a>
				</ItemBox>
				<ItemBox>
					<img className="m-auto h-10 my-2" src={iconPoolDeep} alt="" />
					<p>Total BNB in liquidity pool</p>
					<p className="text-xs mt-2">
						BNB {utils.formatEther(totalLiquidityBNB)}
					</p>
				</ItemBox>
				<ItemBox>
					<img className="m-auto h-10 my-2" src={iconContract} alt="" />
					<p>Total BNB in reward pool</p>
					<p className="text-xs mt-2">BNB {utils.formatEther(rewardPool)}</p>
				</ItemBox>
				<ItemBox>
					<img className="m-auto h-10 my-2" src={iconComputer} alt="" />
					<p>Your $MOONBAR balance</p>
					{(isFetchingData && <Spinner />) || (
						<p className="text-xs mt-2">
							$MOONBAR{" "}
							{parseFloat(utils.formatUnits(userMoonbarBalance, 9)).toFixed(2)}
						</p>
					)}
				</ItemBox>
			</div>
		</section>
	);
};

export default Dashboard;
