import React from "react";
import { useWallet } from "@binance-chain/bsc-use-wallet";

import Button from "./components/Button";
import RequiresWallet from "./components/RequiresWallet";

import imageLogo from "./logo.png";

const Header = () => {
	const { account, connect, reset } = useWallet();

	let button = <></>;
	if (account) {
		button = <Button onClick={reset}>{account.slice(0, 6)}... LOGOUT</Button>;
	}

	return (
		<header className="">
			<div className="container m-auto grid grid-cols-2">
				<div className="text-left p-4">
					<img
						className="h-16 inline-block transform transition duration-300 hover:-rotate-180"
						src={imageLogo}
						alt=""
					/>
					<p className="text-white font-bold font-snickers text-4xl inline-block align-middle">
						MOONBAR
					</p>
				</div>
				<div className="text-right p-4">
					<RequiresWallet>{button}</RequiresWallet>
				</div>
			</div>
		</header>
	);
};

export default Header;
