import { BscConnector } from "@binance-chain/bsc-connector";
import { UseWalletProvider } from "@binance-chain/bsc-use-wallet";

import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Header";

import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <UseWalletProvider
      chainId={56}
      connectors={{
        bsc: {
          web3ReactConnector() {
            return new BscConnector({ supportedChainIds: [56, 97] });
          },
          handleActivationError(err: any) {
            console.log("err from connector");
            console.log(err);
          },
        },
      }}
    >
      <Header />
      <div className="my-20">
        <Dashboard />
      </div>
    </UseWalletProvider>
  );
}

export default App;
