import React from "react";

interface Props {
	height?: number;
	width?: number;
}

const Spinner: React.FC<Props> = ({ height = 50, width = 50 }) => {
	return (
		<div
			style={{
				height,
				width,
			}}
			className="lds-ripple text-center m-auto"
		>
			<div></div>
			<div></div>
		</div>
	);
};

export default Spinner;
