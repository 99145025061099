import React, { FunctionComponent } from "react";

interface Props {
	children: React.ReactNode;
}

const ItemBox: FunctionComponent<Props> = ({ children }) => {
	return (
		<div className="rounded bg-whitetranslucent p-5 text-center">
			{children}
		</div>
	);
};

export default ItemBox;
