import { useWallet } from "@binance-chain/bsc-use-wallet";
import { providers } from "ethers";
import { useEffect, useRef, useState } from "react";

const jsonRpcProvider = new providers.JsonRpcProvider(
  "https://bsc-dataseed1.defibit.io/"
);

type ProviderType = providers.Web3Provider | providers.JsonRpcProvider;

const useEthers = (): ProviderType => {
  const { ethereum }: { ethereum: providers.ExternalProvider } = useWallet();

  const refEth = useRef(ethereum);
  const [ethersObj, setEthersObj] = useState<ProviderType>(
    ethereum !== undefined
      ? new providers.Web3Provider(ethereum)
      : jsonRpcProvider
  );

  useEffect(() => {
    if (ethereum !== refEth.current) {
      setEthersObj(
        ethereum !== undefined
          ? new providers.Web3Provider(ethereum)
          : jsonRpcProvider
      );
      refEth.current = ethereum;
    }
  }, [ethereum]);

  return ethersObj;
};

export default useEthers;
