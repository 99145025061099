import React, { FunctionComponent } from "react";

interface Props {
	children: React.ReactNode;
	onClick?: () => void;
	disabled?: boolean;
}

const ItemBox: FunctionComponent<Props> = ({
	children,
	onClick,
	disabled = false,
}) => {
	const classesActive =
		"transition duration-500 ease-in-out bg-blue rounded-lg text-white px-7 py-2 bg-gradient-to-r from-pink-500 to-blue-500 transform hover:-translate-y-1 hover:scale-105";
	const classesInactive =
		"rounded-lg bg-gray text-white px-7 py-2 cursor-not-allowed";

	return (
		<button
			onClick={!disabled ? onClick : () => {}}
			style={{
				outline: "none",
			}}
			className={disabled ? classesInactive : classesActive}
		>
			{children}
		</button>
	);
};

export default ItemBox;
